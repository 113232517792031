<template>
  <div>
    <b-card
      title="Card Title"
      no-body
    >
      <b-card-header
        header-tag="nav"
        class="pt-1"
      >
        <div class="col-10">
          <b-nav
            card-header
            tabs
          >
            <b-nav-item
              :active="statusFilter == 'favorit' ? true : false"
              :to="{
                name: 'pemeriksaanPemeriksaanFavorit',
                params: { status: 'favorit' },
                query: {
                  'jenis-pemeriksaan': jenisPemeriksaanFilter,
                  'tanggal-mulai': tMulai,
                  'tanggal-akhir': tAkhir,
                },
              }"
              v-if="$can('read', 'pemeriksaan-pemeriksaan-favorit')"
            >
              Favorit&nbsp;
              <b-badge variant="light-success">
                {{ cFavoritStandar + cFavoritTitipProcessing }}
              </b-badge>
            </b-nav-item>
            <b-nav-item
              :active="statusFilter == 'batal' ? true : false"
              :to="{
                name: 'pemeriksaanPemeriksaanBatal',
                params: { status: 'batal' },
                query: {
                  'jenis-pemeriksaan': jenisPemeriksaanFilter,
                  'tanggal-mulai': tMulai,
                  'tanggal-akhir': tAkhir,
                },
              }"
              v-if="$can('read', 'pemeriksaan-pemeriksaan-batal')"
            >
              Batal&nbsp;
              <b-badge variant="light-danger">
                {{ cBatal }}
              </b-badge>
            </b-nav-item>
            <b-nav-item
              :active="statusFilter == 'draf' ? true : false"
              :to="{
                name: 'pemeriksaanPemeriksaanDraf',
                params: { status: 'draf' },
                query: {
                  'jenis-pemeriksaan': jenisPemeriksaanFilter,
                  'tanggal-mulai': tMulai,
                  'tanggal-akhir': tAkhir,
                },
              }"
              v-if="$can('read', 'pemeriksaan-pemeriksaan-draf')"
            >
              Draf&nbsp;
              <b-badge variant="light-secondary">
                {{ cDraf }}
              </b-badge>
            </b-nav-item>
            <b-nav-item
              :active="statusFilter == 'register' ? true : false"
              :to="{
                name: 'pemeriksaanPemeriksaanRegister',
                params: { status: 'register' },
                query: {
                  'jenis-pemeriksaan': jenisPemeriksaanFilter,
                  'tanggal-mulai': tMulai,
                  'tanggal-akhir': tAkhir,
                },
              }"
              v-if="$can('read', 'pemeriksaan-pemeriksaan-register')"
            >
              Register&nbsp;
              <b-badge variant="light-warning">
                {{ cRegister }}
              </b-badge>
            </b-nav-item>
            <b-nav-item
              :active="statusFilter == 'processing' ? true : false"
              :to="{
                name: 'pemeriksaanPemeriksaanProcessing',
                params: { status: 'processing' },
                query: {
                  'jenis-pemeriksaan': jenisPemeriksaanFilter,
                  'tanggal-mulai': tMulai,
                  'tanggal-akhir': tAkhir,
                },
              }"
              v-if="$can('read', 'pemeriksaan-pemeriksaan-processing')"
            >
              Processing&nbsp;
              <b-badge variant="light-info">
                {{ cProcessing + cSusul + cPld + cKonfirmasi }}
              </b-badge>
            </b-nav-item>
            <b-nav-item
              :active="statusFilter == 'diagnosa' ? true : false"
              :to="{
                name: 'pemeriksaanPemeriksaanDiagnosa',
                params: { status: 'diagnosa' },
                query: {
                  'jenis-pemeriksaan': jenisPemeriksaanFilter,
                  'tanggal-mulai': tMulai,
                  'tanggal-akhir': tAkhir,
                },
              }"
              v-if="$can('read', 'pemeriksaan-pemeriksaan-diagnosa')"
            >
              Diagnosa&nbsp;
              <b-badge variant="light-primary">
                {{ cDiagnosaStandar + cDiagnosaTitipProcessing }}
              </b-badge>
            </b-nav-item>
            <b-nav-item
              :active="statusFilter == 'konsultasi' ? true : false"
              :to="{
                name: 'pemeriksaanPemeriksaanKonsultasi',
                params: { status: 'konsultasi' },
                query: {
                  'jenis-pemeriksaan': jenisPemeriksaanFilter,
                  'tanggal-mulai': tMulai,
                  'tanggal-akhir': tAkhir,
                },
              }"
              v-if="$can('read', 'pemeriksaan-pemeriksaan-konsultasi')"
            >
              Konsultasi&nbsp;
              <b-badge variant="light-primary">
                {{ cKonsultasi }}
              </b-badge>
            </b-nav-item>
            <b-nav-item
              :active="statusFilter == 'selesai' ? true : false"
              :to="{
                name: 'pemeriksaanPemeriksaanSelesai',
                params: { status: 'selesai' },
                query: {
                  'jenis-pemeriksaan': jenisPemeriksaanFilter,
                  'tanggal-mulai': tMulai,
                  'tanggal-akhir': tAkhir,
                },
              }"
              v-if="$can('read', 'pemeriksaan-pemeriksaan-selesai')"
            >
              Selesai&nbsp;
              <b-badge variant="light-success">
                {{ cSelesaiStandar + cSelesaiTitipProcessing }}
              </b-badge>
            </b-nav-item>
            <b-nav-item
              :active="statusFilter == 'semua' ? true : false"
              :to="{
                name: 'pemeriksaanPemeriksaanSemua',
                params: { status: 'semua' },
                query: {
                  'jenis-pemeriksaan': jenisPemeriksaanFilter,
                  'tanggal-mulai': tMulai,
                  'tanggal-akhir': tAkhir,
                },
              }"
              v-if="$can('read', 'pemeriksaan-pemeriksaan-semua')"
            >
              Semua&nbsp;
              <b-badge variant="light-success">
                {{ cSemuaStandar + cSemuaTitipProcessing }}
              </b-badge>
            </b-nav-item>
          </b-nav>
        </div>
        <div
          class="col-2 text-right pr-0"
          v-if="$can('create', 'pemeriksaan-pemeriksaan')"
        >
          <b-card-title class="text-primary">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              :to="{ name: 'pemeriksaanPemeriksaanTambah' }"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Tambah</span>
            </b-button>
          </b-card-title>
        </div>
        <router-view
          :jenisPemeriksaanFilter="jenisPemeriksaanFilter"
          :tMulai="tMulai"
          :tAkhir="tAkhir"
          :cProcessing="cProcessing"
          :cSusul="cSusul"
          :cPld="cPld"
          :cKonfirmasi="cKonfirmasi"
          :cDiagnosaStandar="cDiagnosaStandar"
          :cDiagnosaTitipProcessing="cDiagnosaTitipProcessing"
          :cSelesaiStandar="cSelesaiStandar"
          :cSelesaiTitipProcessing="cSelesaiTitipProcessing"
          :cFavoritStandar="cFavoritStandar"
          :cFavoritTitipProcessing="cFavoritTitipProcessing"
          :cSemuaStandar="cSemuaStandar"
          :cSemuaTitipProcessing="cSemuaTitipProcessing"
          :status="status"
          @subStatus="getSubStatus"
        />
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            cols="6"
            offset-md="3"
          >
            <h4>Filter</h4>
            <b-form
              class="needs-validation"
              @submit.prevent="submitFilter"
            >
              <b-col
                offset-md="1"
                cols="10"
              >
                <b-form-group
                  label="Tanggal"
                  label-for="tanggal"
                >
                  <flat-pickr
                    v-model="tanggalFilter"
                    class="form-control"
                    :config="{ mode: 'range', dateFormat: 'd-m-Y' }"
                  />
                </b-form-group>
                <b-form-group
                  label="Jenis Pemeriksaan"
                  label-for="jenisPemeriksaan"
                >
                  <b-form-select
                    v-model="jenisPemeriksaanFilter"
                    id="jenisPemeriksaan"
                    :options="dataJenisPemeriksaan"
                  />
                </b-form-group>
                <b-form-group
                  label="Status Bayar"
                  label-for="statusBayar"
                >
                  <b-form-select
                    v-model="statusBayarFilter"
                    id="statusBayar"
                    :options="dataStatusBayar"
                  />
                </b-form-group>
              </b-col>
              <b-col
                offset-md="9"
                cols="2"
                align="right"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  type="submit"
                >
                  Filter
                </b-button>
              </b-col>
            </b-form>
          </b-col>
          <b-col cols="12">
            <!-- search input -->
            <div class="custom-search d-flex justify-content-between">
              <div class="align-self-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  size="sm"
                  @click="clickPrint"
                  class="mr-1"
                >
                  <div v-if="isPrint">
                    <b-spinner small />
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div v-else>
                    <feather-icon
                      icon="FileTextIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Print Excel</span>
                  </div>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  size="sm"
                  @click="clickBatal"
                  class="mr-1"
                  v-if="(status == 'draf' || status == 'register') && dataSelectedRows.length > 0"
                >
                  Batal
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  size="sm"
                  @click="chageStatus('Processing')"
                  v-if="
                    status == 'register' &&
                    $can('update', 'pemeriksaan-pemeriksaan-register') && 
                    dataSelectedRows.length > 0
                  "
                >
                  Processing
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  @click="chageStatus('Diagnosa')"
                  v-if="
                    status == 'processing' &&
                    $can('update', 'pemeriksaan-pemeriksaan-processing') &&
                    dataSelectedRows.length > 0
                  "
                >
                  Diagnosa
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  size="sm"
                  @click="chageStatus('Selesai')"
                  v-if="
                    status == 'diagnosa' &&
                    subStatusFilter == 'titipProcessing' &&
                    $can('update', 'pemeriksaan-pemeriksaan-diagnosa-status') && 
                    dataSelectedRows.length > 0
                  "
                >
                  Selesai
                </b-button>
              </div>
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Cari</label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Cari"
                    type="text"
                    class="d-inline-block"
                    @input="handleSearch"
                  />
                </div>
              </b-form-group>
            </div>
            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: row,
                jumpFirstOrLast: true,
              }"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              mode="remote"
              @on-sort-change="onSortChange"
              :select-options="{
                enabled: status != 'selesai',
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              @on-selected-rows-change="selectionChangedTable"
              :sort-options="{
                enabled: true,
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: noSediaan -->
                <span
                  v-if="props.column.field === 'noSediaan'"
                  class="align-self-center"
                >
                  <router-link :to="{
                      name: 'pemeriksaanPemeriksaanUbah',
                      params: { id: props.row.id },
                    }">{{ props.formattedRow[props.column.field] }}</router-link>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    :variant="
                      props.row.isPrint == 1 ? 'flat-danger' : 'flat-primary'
                    "
                    class="btn-icon rounded-circle"
                    @click="generatePDF(props.row.id, props.index)"
                    v-if="props.row.status == 'Selesai'"
                    v-b-tooltip.hover
                    title="Unduh"
                  >
                    <feather-icon icon="FileTextIcon" />
                  </b-button>
                </span>

                <!-- Column: nama -->
                <span
                  v-else-if="props.column.field === 'nama'"
                  class="align-self-center"
                >
                  {{ props.formattedRow[props.column.field] }}
                  <b-badge variant="light-primary">{{
                    props.row.jenisKelamin + " / " + props.row.umur
                  }}</b-badge>
                </span>

                <!-- Column: nama -->
                <span
                  v-else-if="props.column.field === 'kesimpulan'"
                  class="align-self-center"
                >
                  <span v-html="props.row.kesimpulan"></span>
                </span>

                <!-- Column: aksi -->
                <span v-else-if="props.column.field === 'aksi'">
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item
                        @click="
                          clickToRegister(
                            props.row.id,
                            props.row.idJenisPemeriksaan
                          )
                        "
                        v-if="
                          (status == 'draf' || status == 'batal') &&
                          $can('update', 'pemeriksaan-pemeriksaan-draf')
                        "
                      >
                        <feather-icon
                          icon="ArrowRightIcon"
                          class="mr-50"
                        />
                        <span>Ke Register</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :to="{
                          name: 'pemeriksaanPemeriksaanUbah',
                          params: { id: props.row.id },
                        }"
                        v-if="status == 'diagnosa'"
                      >
                        <feather-icon
                          icon="ArrowRightIcon"
                          class="mr-50"
                        />
                        <span>Diagnosa</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :to="{
                          name: 'pemeriksaanPemeriksaanUbah',
                          params: { id: props.row.id },
                        }"
                        v-if="
                          status != 'batal' &&
                          $can('update', 'pemeriksaan-pemeriksaan')
                        "
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          class="mr-50"
                        />
                        <span>Ubah</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="deleteButton(props.row.id)"
                        v-if="
                          status == 'batal' &&
                          $can('delete', 'pemeriksaan-pemeriksaan')
                        "
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="mr-50"
                        />
                        <span>Hapus</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Menampilkan 1 hingga </span>
                    <b-form-select
                      v-model="row"
                      :options="['10', '100', '1000', '10000']"
                      class="mx-1"
                      @input="handlePageChange"
                    />
                    <span class="text-nowrap">
                      dari {{ totalRecords }} entri
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="totalRecords"
                      :per-page="row"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @change="handleChangePage"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- modal toRegister-->
    <b-modal
      cancel-variant="outline-secondary"
      centered
      title="Pindahkan Ke Register"
      ref="modal-register"
    >
      <b-form>
        <b-form-group>
          <label for="jenisPemeriksaan">Jenis Pemeriksaan</label>
          <b-form-input
            id="jenisPemeriksaan"
            readonly
            v-model="jenisPemeriksaan"
          />
        </b-form-group>
        <b-form-group>
          <label for="noLab">No Lab</label>
          <b-form-input
            id="noLab"
            readonly
            v-model="noLabTemp"
          />
        </b-form-group>
        <b-form-group>
          <label for="dokterPemeriksa">Dokter Pemeriksa</label>
          <b-form-select
            id="dokterPemeriksa"
            v-model="registerDokterPemeriksa"
            :options="dataDokterPemeriksa"
            :state="isErrorRegisterDokterPemeriksa"
          />
          <small
            v-if="isErrorRegisterDokterPemeriksa == false"
            class="text-danger"
          >Dokter Pemeriksa Tidak Boleh Kosong</small>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="ClickMoveToRegister"
          >
            Simpan
          </b-button>
        </div>
      </template>
    </b-modal>
    <div v-show="false">
      {{ $route.fullPath }}
    </div>
    <GenerateHasil ref="generateHasil" />
  </div>
</template>

<script>
import {
  VBTooltip,
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BPagination,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BForm,
  BTabs,
  BTab,
  BNav,
  BNavItem,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, onUpdated } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import { jsPDF } from "jspdf";
import autotable from "jspdf-autotable";
import moment from 'moment'
const qr = require("qrcode");
import { utils, writeFileXLSX } from "xlsx";
import helper from '@/libs/helper'
import GenerateHasil from '@/views/pemeriksaan/pemeriksaan/GenerateHasil.vue'

export default {
  setup(props, context) {
    const g = context.root;
    const $http = g.$http;
    const route = g.$route;

    let rows = ref([]);
    let searchTerm = ref("");
    let start = ref(1);
    let row = ref(10);
    let totalRecords = ref(0);
    let sortColumn = ref("");
    let order = ref("");
    var columns = ref([]);

    let isLoading = ref(false);
    let isPrint = ref(false);

    var dataJenisPemeriksaan = ref([]);
    var dataSelectedRows = ref([]);
    var jenisPemeriksaanFilter = ref(route.query["jenis-pemeriksaan"] || "");
    var tMulai = ref(
      route.query["tanggal-mulai"] || moment().format("01-MM-YYYY")
    );
    var tAkhir = ref(
      route.query["tanggal-akhir"] || moment().format("DD-MM-YYYY")
    );
    var tanggalFilter = ref(
      tMulai.value || tAkhir.value
        ? `${tMulai.value} to ${tAkhir.value}`
        : moment().format("01-MM-YYYY") + " to " + moment().format("DD-MM-YYYY")
    );
    var statusFilter = ref(props.status);
    var subStatusFilter = ref("");
    var statusBayarFilter = ref(route.query["status-bayar"] || "");

    var dataStatusBayar = ref([
      { text: "-- Semua --", value: "" },
      { text: "Belum Ditagih", value: "Belum Ditagih" },
      { text: "Sudah Ditagih", value: "Sudah Ditagih" },
      { text: "Belum Bayar", value: "Belum Bayar" },
      { text: "Sudah Bayar", value: "Sudah Bayar" },
    ]);

    var jenisPemeriksaan = ref("");
    var noLab = ref("");
    var noLabTemp = ref("");
    var idPesanan = ref("");
    var registerDokterPemeriksa = ref("");

    var dataDokterPemeriksa = ref([]);

    var isErrorRegisterDokterPemeriksa = ref(false);

    var cBatal = ref(0);
    var cDraf = ref(0);
    var cRegister = ref(0);
    var cProcessing = ref(0);
    var cSusul = ref(0);
    var cPld = ref(0);
    var cKonfirmasi = ref(0);
    var cDiagnosaStandar = ref(0);
    var cKonsultasi = ref(0);
    var cDiagnosaTitipProcessing = ref(0);
    var cSelesaiStandar = ref(0);
    var cSelesaiTitipProcessing = ref(0);
    var cFavoritStandar = ref(0);
    var cFavoritTitipProcessing = ref(0);
    var cSemuaStandar = ref(0);
    var cSemuaTitipProcessing = ref(0);

    var pJenisPemeriksaan = ref("");
    var pNoSediaan = ref("");
    var pNik = ref("");
    var pNama = ref("");
    var pNoBpjs = ref("");
    var pUmurTglLahir = ref("");
    var pNoRekamMedis = ref("");
    var pJenisKelamin = ref("");
    var pAlamat = ref("");
    var pTanggalDiterima = ref("");
    var pJamOperasi = ref("");
    var pTanggalDijawab = ref("");
    var pJamFiksasi = ref("");
    var pSediaan = ref("");
    var pJumlahJaringan = ref("");
    var pLokasiAsalJaringan = ref("");
    var pFiksasi = ref("");
    var pTopography = ref("");
    var pIsVolFiksasi10x = ref("");
    var pDiagnosaKlinis = ref("");
    var pKeteranganKlinis = ref("");
    var pRiwayatLabPa = ref("");
    var pMakroskopik = ref("");
    var pMikroskopik = ref("");
    var pKesimpulan = ref("");
    var pDerajatHistopatologi = ref("");
    var pMorphology = ref("");
    var pIcdO = ref("");
    var pDokterPemeriksa = ref("");
    var pCatatan = ref("");
    var pAnjuran = ref("");
    var labelPdf1 = ref("Jam Mulai Operasi");
    var labelPdf2 = ref("Jam Mulai Fiksasi");
    var labelPdf3 = ref("Jumlah Wadah / Jaringan yang Diterima");
    var labelPdf4 = ref("Fiksasi yang Digunakan");
    var valuePdf1 = ref("");
    var valuePdf2 = ref("");
    var valuePdf3 = ref("");
    var valuePdf4 = ref("");

    const selectionChangedTable = (data) => {
      dataSelectedRows.value = data.selectedRows;
    };

    onUpdated(() => {
      statusFilter.value = props.status;
    });

    const getData = async (length = row.value) => {
      const result = await $http({
        url: "/pemeriksaan/pemeriksaan/get-data",
        params: {
          start: start.value - 1,
          row: length,
          search: searchTerm.value,
          sortColumn: sortColumn.value,
          order: order.value,
          jenisPemeriksaanFilter: jenisPemeriksaanFilter.value,
          statusFilter: statusFilter.value,
          tanggalFilter: tanggalFilter.value,
          subStatusFilter: subStatusFilter.value,
          statusBayarFilter: statusBayarFilter.value,
        },
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then(res => ({
          ...res.data,
          isSuccess: true
        }))
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error: " + err.message;

          return {
            isSuccess: false,
            pesan: showError
          }
        });

      if(result.isSuccess){
        return result
      }
      else {
        g.$bvToast.toast(result.pesan || 'Something Wrong', {
            title: `Error Load Table`,
            variant: 'danger',
            solid: true,
        })
        return {}
      }

    }

    const datatable = async () => {
      isLoading.value = true;
      
      const result = await getData()

      rows.value = result.data;
      totalRecords.value = result.totalRecord;

      isLoading.value = false;
    };

    const getJumlahStatus = () => {
      $http({
        url: "/pemeriksaan/pemeriksaan/get-total-status",
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          var {
            batal,
            diagnosa,
            draf,
            favorit,
            processing,
            register,
            selesai,
            semua,
            konsultasi,
          } = res.data.data.cData;
          var { processing, susul, pld, konfirmasi } = processing;
          cBatal.value = batal;
          cDraf.value = draf;
          cRegister.value = register;

          cProcessing.value = processing;
          cSusul.value = susul;
          cPld.value = pld;
          cKonfirmasi.value = konfirmasi;

          cDiagnosaStandar.value = diagnosa.standar;
          cDiagnosaTitipProcessing.value = diagnosa.titipProcessing;

          cKonsultasi.value = konsultasi;

          cSelesaiStandar.value = selesai.standar;
          cSelesaiTitipProcessing.value = selesai.titipProcessing;
          cFavoritStandar.value = favorit.standar;
          cFavoritTitipProcessing.value = favorit.titipProcessing;
          cSemuaStandar.value = semua.standar;
          cSemuaTitipProcessing.value = semua.titipProcessing;
        })
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Load Table`,
            variant: "danger",
            solid: true,
          });
        });
    };

    const generatePDF = async (id, index) => {
      await context.refs.generateHasil.generatePDF(id, {isPrint: 1}, 'download')

      rows.value[index].isPrint = 1;
    };

    const spesialStatus = () => {
      const columnLeft = [
          {
            label: "No",
            field: "no",
            width: "1%",
          },
          {
            label: "No Lab",
            field: "noSediaan",
          },
          {
            label: "Nama",
            field: "nama",
          },
          {
            label: "Tanggal Terima",
            field: "tanggalTerima",
          },
      ]
      const columnMid = [
        {
          label: "RS / Klinik",
          field: "rsKlinik",
        },
        {
          label: "Pengirim",
          field: "dokterPengirim",
        },
        {
          label: "Dokter Pemeriksa",
          field: "dokterPemeriksa",
        },
        {
          label: "No Rekam Medik",
          field: "noRekamMedis",
        },
      ]
      if (statusFilter.value == "semua" || statusFilter.value == "selesai") {
        order.value = "desc";
        sortColumn.value = "no";

        columns.value = [
          ...columnLeft,
          {
            label: "Tanggal Dijawab",
            field: "tanggalJawab",
          },
          ...columnMid,
          {
            label: "Kesimpulan",
            field: "kesimpulan",
          },
          {
            label: "Aksi",
            field: "aksi",
            width: "1%",
            sortable: false,
          },
        ];
      } else {
        order.value = "asc";
        sortColumn.value = "no";

        columns.value = [
          ...columnLeft,
          ...columnMid,
          {
            label: "Aksi",
            field: "aksi",
            width: "1%",
            sortable: false,
          },
        ];
      }
    };

    spesialStatus();

    watch(statusFilter, () => {
      spesialStatus();
      start.value = 1;
      datatable();
    });

    watch(subStatusFilter, () => {
      start.value = 1;
      datatable();
    });

    watch(registerDokterPemeriksa, () => {
      isErrorRegisterDokterPemeriksa.value =
        typeof registerDokterPemeriksa.value == "undefined" ||
        registerDokterPemeriksa.value == "" ||
        registerDokterPemeriksa.value == null
          ? false
          : null;
    });

    if (
      route.query["jenis-pemeriksaan"] ||
      route.query["jenis-pemeriksaan"] == "" ||
      route.query["tanggal-mulai"] ||
      route.query["tanggal-mulai"] == "" ||
      route.query["tanggal-akhir"] ||
      route.query["tanggal-akhir"] == ""
    )
      datatable();

    const submitFilter = () => {
      start.value = 1;
      if (tanggalFilter.value) {
        if (tanggalFilter.value.includes(" to ")) {
          let splitTanggal = tanggalFilter.value.split(" to ");
          tMulai.value = splitTanggal[0];
          tAkhir.value = splitTanggal[1];
        } else tMulai.value = tAkhir.value = tanggalFilter.value;
      }
      let url = `?jenis-pemeriksaan=${jenisPemeriksaanFilter.value}&tanggal-mulai=${tMulai.value}&tanggal-akhir=${tAkhir.value}&status-bayar=${statusBayarFilter.value}`;
      window.history.pushState("", "", url);
      datatable();
    };

    const getSubStatus = (val) => {
      subStatusFilter.value = val;
    };

    const ClickMoveToRegister = () => {
      if (isErrorRegisterDokterPemeriksa.value != false) {
        $http({
          url: "/pemeriksaan/pemeriksaan/set-register",
          data: {
            id: idPesanan.value,
            noLab: noLab.value,
            dokterPemeriksa: registerDokterPemeriksa.value,
          },
          method: "post",
          headers: {
            Authorization: "Bearer " + g.$cookies.get("token"),
          },
        })
          .then((res) => {
            datatable();
            getJumlahStatus();
          })
          .catch((err) => {
            let showError = err.response
              ? err.response.data
              : err.request
              ? err.request
              : "Error" + err.message;

            g.$bvToast.toast(showError || "Something Wrong", {
              title: `Error Set Register`,
              variant: "danger",
              solid: true,
            });
          });

        context.refs["modal-register"].hide();
      }
    };

    const clickProcessing = () => {
      chageStatus("Processing");
    };

    const chageStatus = (status) => {
      $http({
        url: "/pemeriksaan/pemeriksaan/set-status",
        data: {
          dataPesanan: dataSelectedRows.value,
          status,
        },
        method: "post",
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          datatable();
          getJumlahStatus();
        })
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Set Process`,
            variant: "danger",
            solid: true,
          });
        });
    };

    const clickBatal = () => {
      chageStatus("Batal");
    };

    const clickFavorit = (id, isFavorit, index) => {
      isFavorit = isFavorit == 0 ? "1" : "0";
      $http({
        url: "/pemeriksaan/pemeriksaan/set-favorit",
        data: {
          isFavorit,
          id,
        },
        method: "post",
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          rows.value[index].isFavorit = isFavorit;
        })
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Set Favorit`,
            variant: "danger",
            solid: true,
          });
        });
    };

    const clickToRegister = (id, idJenisPemeriksaan) => {
      $http({
        url: "/pemeriksaan/jenisPemeriksaan/get-data-jenis-pemeriksaan-value",
        params: {
          id: idJenisPemeriksaan,
          idPesanan: id,
        },
        headers: {
          Authorization: "Bearer " + g.$cookies.get("token"),
        },
      })
        .then((res) => {
          const { jenisPemeriksaanVal, noSediaanVal, dokterPemeriksaVal } =
            res.data.data;
          jenisPemeriksaan.value = jenisPemeriksaanVal;
          noLab.value = noSediaanVal;
          noLabTemp.value = noSediaanVal;
          registerDokterPemeriksa.value = dokterPemeriksaVal;
          dataDokterPemeriksa.value =
            res.data.data.dataJenisPemeriksaan.dataDokterPemeriksaVal;

          idPesanan.value = id;
        })
        .catch((err) => {
          let showError = err.response
            ? err.response.data
            : err.request
            ? err.request
            : "Error" + err.message;

          g.$bvToast.toast(showError || "Something Wrong", {
            title: `Error Set Favorit`,
            variant: "danger",
            solid: true,
          });
        });

      context.refs["modal-register"].show();
    };

    const handleSearch = (searching) => {
      start.value = 1;
      datatable();
    };

    const handlePageChange = (active) => {
      datatable();
    };

    const handleChangePage = (page) => {
      start.value = (page - 1) * row.value + 1;
      datatable();
    };

    const onSortChange = (params) => {
      sortColumn.value = params[0].field;
      order.value = params[0].type;
      datatable();
    };

    const deleteButton = (id) => {
      g.$swal({
        title: "Apakah Anda Yakin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          $http({
            url: "/pemeriksaan/pemeriksaan/" + id,
            method: "delete",
            headers: {
              Authorization: "Bearer " + g.$cookies.get("token"),
            },
          })
            .then(() => {
              datatable();
            })
            .catch((err) => {
              let showError = err.response
                ? err.response.data
                : err.request
                ? err.request
                : "Error" + err.message;

              g.$bvToast.toast(showError || "Something Wrong", {
                title: `Error Load Table`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    };

    getJumlahStatus();

    const clickPrint = async () => {
      isPrint.value = true

      try {
        const result = await getData(100000)

        // rows.value = result.data;
        // totalRecords.value = result.totalRecord;

        let data = [
          [
            "No",
            "No Lab",
            "Nama",
            "Tanggal Terima",
            "Tanggal Dijawab",
            "RS / Klinik",
            "Pengirim",
            "Dokter Pemeriksa",
            "No Rekam Medik",
            "Kesimpulan",
          ],
        ];
        let no = 1
        for (let i = 0; i < result.data.length; i++) {
          const {
            noSediaan, nama, tanggalTerima, tanggalJawab, rsKlinik, dokterPengirim, dokterPemeriksa, kesimpulan, noRekamMedis,
          } = result.data[i];

          data.push([ no, noSediaan, nama, tanggalTerima, tanggalJawab, rsKlinik, dokterPengirim, dokterPemeriksa, noRekamMedis, kesimpulan]);

          no++
        }

        const ws = utils.json_to_sheet([]);
        const wb = utils.book_new();
        utils.sheet_add_aoa(ws, data);
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(
          wb,
          `Pemeriksaan ${moment().format("DD-MM-YYYY HH:mm:ss")}.xlsx`
        );

      } catch (error) {
        console.log(error);
        helper.showError(g, { request: error.toString() }, `Gagal Print Excel`);
      }
      
      isPrint.value = false
    }

    return {
      handleSearch,
      handlePageChange,
      handleChangePage,
      onSortChange,
      submitFilter,
      selectionChangedTable,
      clickProcessing,
      clickBatal,
      clickFavorit,
      clickToRegister,
      ClickMoveToRegister,
      getSubStatus,
      generatePDF,
      chageStatus,
      deleteButton,
      clickPrint,

      tanggalFilter,
      statusFilter,
      dataJenisPemeriksaan,
      jenisPemeriksaanFilter,
      rows,
      searchTerm,
      start,
      row,
      totalRecords,
      sortColumn,
      order,
      isLoading,
      isPrint,
      dataSelectedRows,
      tMulai,
      tAkhir,
      jenisPemeriksaan,
      noLab,
      noLabTemp,
      idPesanan,
      subStatusFilter,
      registerDokterPemeriksa,
      columns,
      statusBayarFilter,
      dataStatusBayar,

      dataDokterPemeriksa,

      isErrorRegisterDokterPemeriksa,

      pNoSediaan,
      pNik,
      pNama,
      pNoBpjs,
      pUmurTglLahir,
      pNoRekamMedis,
      pJenisKelamin,
      pAlamat,
      pTanggalDiterima,
      pJamOperasi,
      pTanggalDijawab,
      pJamFiksasi,
      pSediaan,
      pJumlahJaringan,
      pLokasiAsalJaringan,
      pFiksasi,
      pTopography,
      pIsVolFiksasi10x,
      pDiagnosaKlinis,
      pKeteranganKlinis,
      pRiwayatLabPa,
      pMakroskopik,
      pMikroskopik,
      pKesimpulan,
      pDerajatHistopatologi,
      pMorphology,
      pIcdO,
      pDokterPemeriksa,
      labelPdf1,
      labelPdf2,
      labelPdf3,
      labelPdf4,
      valuePdf1,
      valuePdf2,
      valuePdf3,
      valuePdf4,
      pJenisPemeriksaan,
      pCatatan,
      pAnjuran,

      cBatal,
      cDraf,
      cRegister,
      cProcessing,
      cSusul,
      cPld,
      cKonfirmasi,
      cDiagnosaStandar,
      cDiagnosaTitipProcessing,
      cSelesaiStandar,
      cSelesaiTitipProcessing,
      cFavoritStandar,
      cFavoritTitipProcessing,
      cSemuaStandar,
      cSemuaTitipProcessing,
      cKonsultasi,
    };
  },
  components: {
    GenerateHasil,
    flatPickr,
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BForm,
    BTabs,
    BTab,
    BNav,
    BNavItem,
    BSpinner,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  created() {
    this.$http({
      url: "/pemeriksaan/pemeriksaan",
      params: {
        jenisPemeriksaan: this.jenisPemeriksaanFilter,
      },
      headers: {
        Authorization: "Bearer " + $cookies.get("token"),
      },
    })
      .then((res) => {
        const { jenisPemeriksaanVal, dataJenisPemeriksaan } = res.data.data;
        this.jenisPemeriksaanFilter = jenisPemeriksaanVal;
        this.dataJenisPemeriksaan = dataJenisPemeriksaan;
      })
      .catch((err) => {
        let showError = err.response
          ? err.response.data
          : err.request
          ? err.request
          : "Error" + err.message;

        this.$bvToast.toast(showError || "Something Wrong", {
          title: `Error Load Table`,
          variant: "danger",
          solid: true,
        });
      });
  },
  props: {
    status: String,
    subStatus: String,
  },
};
</script>

<style lang="scss" scope>
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.pdfobject-container {
  height: 80rem;
  border: 1rem solid rgba(0, 0, 0, 0.1);
}
</style>